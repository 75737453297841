import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import Home from "../../components/layouts/home";
import SEO from "../../components/seo/seo";
import HeroChevron from "../../components/hero/hero-chevron";
import ServiceStates from "../../components/service-states/service-states";
import QuickLinks from "../../components/quick-links/quick-links";
import PersonalizationCards from "../../components/card/personalization-cards";

// Heplers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import LargeCards from "../../components/card/large-cards";

const SpanishIndexPage = () => {
  // NOTE: the new chevron hero image file naming convention below uses the device size rather that the image size.
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      card1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-pos-inventory-062724.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-checking-rewards-082224.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card3ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-business-banking-062824.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-greenlight-062724.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-credit-rewards-082224.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const heroChevronData = {
    id: "spanish-home-page-hero",
    ...getHeroImgVariables(pageData),
    altText: "Una pareja mirándo un proyecto con un contratista mientras sus hijos juegan.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Obtén 1% de descuento en la tasa de tu HELOC"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Aplica Ya",
            url: "/personal-banking/home-loans/heloc-home-equity-line-of-credit#mortgage-calculator-section",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white hero-tertiary-text",
            text: "Se aplican términos y condiciones, sujetos a cambios y aprobación de crédito."
          }
        }
      ]
    }
  };

  const title = "WaFd Bank: Productos y servicios bancarios";

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "WaFd Bank, antes el Banco Washington Federal, le ofrece productos pensando en usted. Nuestros servicios bancarios incluyen cuentas de cheques y de ahorro; hipotecas, préstamos y más."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/homepage/og-heloc-rate-discount-082224.jpg"
      }
    ],
    schema: {}, // TODO: We are missing this
    addSuffix: false
  };

  let personalizationCardData = [
    {
      // CARD SLOT 1
      slotNumber: 1,
      cards: [
        {
          // Default (CD Special)
          cardNumber: 1,
          id: "spanish-homepage-link-card-1",
          imgData: pageData.card1ImageVar.childImageSharp.gatsbyImageData,
          altText: "Compañeros de trabajo tomando inventario en una laptop.",
          heading: "Monitorea tu inventario y administra tus ventas fácilmente",
          subheading: null,
          text: "Soluciones para negocios pequeños y grandes empresas con múltiples sucursales.",
          linkUrl: "/es/banca-de-negocios"
        }
      ]
    },
    {
      // CARD SLOT 2
      slotNumber: 2,
      cards: [
        {
          // Default (Home Equity)
          cardNumber: 1,
          id: "spanish-homepage-link-card-2",
          imgData: pageData.card2ImageVar.childImageSharp.gatsbyImageData,
          altText: "Pareja feliz caminándo afuera con su equipaje.",
          heading: "Obtén recompenzas E interés de tu cuenta de cheques",
          subheading: null,
          text: "Abre una cuenta",
          linkUrl: "/es/banca-personal/rewards-checking",
          tertiaryText: "Las recompensas pueden requerir activación/registro."
        }
      ]
    },
    {
      // CARD SLOT 3
      slotNumber: 3,
      cards: [
        {
          // Default (Smart Start)
          cardNumber: 1,
          id: "spanish-homepage-link-card-3",
          imgData: pageData.card3ImageVar.childImageSharp.gatsbyImageData,
          altText: "Empresario trabajando en un almacén con su teléfono y su láptop.",
          heading: "Comprar un hogar es posible con Smart Start",
          subheading: null,
          text: "¡Puede que seas elegible! Contacta tu sucursal más cercana para averiguarlo.",
          linkUrl: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start"
        }
      ]
    }
  ];

  const largeCardData = [
    {
      // Default (Card 1)
      cardNumber: 1,
      imgData: pageData.largeCard1ImageVar.childImageSharp.gatsbyImageData,
      altText: "Madre e hija viendo el celular de la hija.",
      heading: "Deja que Greenlight te ayude este verano",
      text: "Asigna tareas a tus hijos, dales su mesada y mucho más, cortesía de WaFd.",
      linkUrl: "/es/banca-personal/cuentas-de-ahorro#coverdell-section",
      btnText: "Consigue Greenlight"
    },
    {
      // Default (Card 2)
      cardNumber: 2,
      imgData: pageData.largeCard2ImageVar.childImageSharp.gatsbyImageData,
      altText: "Una pareja deliz en un restaurante con una laptop y una tarjeta de crédito.",
      heading: "Obtén una tarjeta de crédito con Cash Back",
      text: "Maximiza tus ahorros en compras del día-a-día con una tarjeta de crédito WaFd.",
      linkUrl: "/es/banca-personal/tarjetas-de-credito",
      btnText: "Échale un vistazo"
    }
  ];

  return (
    <Home>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <QuickLinks />
      <section className="bg-info">
        <PersonalizationCards containerClass="bg-info" cardData={personalizationCardData} />
        <LargeCards containerClass="bg-info" cardData={largeCardData} lang="es" />
        <ServiceStates containerClass="bg-info" />
      </section>
    </Home>
  );
};

export default SpanishIndexPage;
